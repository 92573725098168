import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import Title from '../components/title'
import Subtitle from '../components/subtitle'
import usePosts from '../hooks/use-posts'
import slugify from '../utils/slugify'

function Section ({ title, children }) {
  return (
    <section className='Facts-content u-sectionPadded u-sectionDivider'>
      <Subtitle style={{ textAlign: 'left', marginBottom: '40px' }}>{title}</Subtitle>
      <ul className='Facts' style={{ justifyContent: 'flex-start' }}>
        {children}
      </ul>
    </section>
  )
}

function Item ({ slug, title }) {
  return (
    <li key={title} className='Facts-item'>
      <Link to={`/${slug}/`}>
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </Link>
    </li>
  )
}

const isProductPage = node => node.pageTemplate === 'template-product.php'

export default function PageSitemap ({ data, location, pageContext }) {
  const { page, pages, shop } = data.wpgraphql
  const { templateId } = pageContext
  const posts = usePosts()

  const allPages = pages.edges.reduce((arr, { node }) => {
    arr.push(node)
    if (node.childPages) {
      arr.push(...node.childPages.edges.map(({ node }) => node))
    }
    return arr
  }, [])

  const productPages = allPages.filter(node => isProductPage(node))
  const otherPages = allPages.filter(node => !isProductPage(node))

  const collections = shop.productCollections.shopCollection.map(item => ({
    title: item.menuLabel,
    slug: slugify(item.menuLabel)
  }))

  return (
    <Layout page={page} template={templateId} path={location.pathname}>
      <article className='Page'>
        <Title className='h1--sm'>Sitemap</Title>
        <Section title='Products'>
          {productPages.map(node => (
            <Item key={node.slug} slug={`shop/${node.slug}`} title={node.title} />
          ))}
        </Section>
        <Section title='Collections'>
          {collections.map(node => (
            <Item key={node.slug} slug={`shop/${node.slug}`} title={node.title} />
          ))}
        </Section>
        <Section title='Pages'>
          {otherPages.map(node => (
            <Item key={node.slug} slug={node.slug} title={node.title} />
          ))}
        </Section>
        <Section title='Blog posts'>
          {posts.map(node => (
            <Item key={node.slug} slug={node.slug} title={node.title} />
          ))}
        </Section>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageSitemapQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        ...PageFragment
      }
      pages(first: 100, where: {status: PUBLISH}) {
        edges {
          node {
            title
            slug
            pageTemplate
            childPages(first: 50) {
              edges {
                node {
                  title
                  slug
                  pageTemplate
                }
              }
            }
          }
        }
      }
      ...ShopPageCollectionsFragment
    }
  }
`
